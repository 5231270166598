.table {
  width: 100%;
  border-radius: $pt-border-radius;
  overflow: hidden;

  .header {
    user-select: none;
  }

  tr {
    td {
      vertical-align: middle;
    }
  }

  .header-cell {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    white-space: nowrap;
    cursor: pointer;
  }

  .header-cell:hover {
    .sorting-icon {
      opacity: 1;
    }
  }

  .sorting-icon {
    width: 16px;
    margin-left: auto;
    opacity: 0.5;
  }

  .sorting-icon.sorting-icon--selected {
    opacity: 1;
  }
}
