@mixin flex-centred($justify: center, $align: center, $direction: row) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin on-dark-theme {
  .#{$ns}-dark & {
    @content;
  }
}

@mixin use-background-color(
  $light: $pt-app-background-color,
  $dark: $pt-dark-app-background-color
) {
  background: $light;
  @include on-dark-theme {
    background: $dark;
  }
}

@mixin for-size($size) {
  @if $size == phone {
    @media (max-width: $breakpoint-mobile) {
      @content;
    }
  } @else if $size == tablet {
    @media (min-width: $breakpoint-tablet) {
      @content;
    }
  } @else if $size == desktop {
    @media (min-width: $breakpoint-desktop) {
      @content;
    }
  }
}

@mixin content-container(
  $vertical-padding: 4,
  $desktop-padding: 4,
  $phone-padding: 2
) {
  height: 100%;
  width: 100%;
  padding: 0 $pt-grid-size * $desktop-padding;

  @include for-size(phone) {
    padding: 0 $pt-grid-size * $phone-padding;
  }

  margin-top: $pt-grid-size * $vertical-padding;
  margin-bottom: $pt-grid-size * $vertical-padding;
}
