.unlink-workflow {
  display: inline-block;

  &__users {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    color: $blue3;
    cursor: pointer;

    .bp4-icon {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    .bp4-progress-bar {
      width: 2rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  &__linked {
    margin-right: 0.4rem;
    margin-bottom: 0.4rem;
  }

  &__user-emails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.workflow-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__name {
    padding-right: 1rem;
  }

  &__date {
    font-size: 0.75rem;
  }

  &__description {
    max-width: 12rem;
    text-overflow: ellipsis;
  }
}
