.app {
  @include use-background-color;
  display: flex;
  flex-direction: column;
  height: 100%;
  height: 100vh;
  width: 100%;
  width: 100vw;

  .app-scroll {
    height: 100%;
    width: 100%;
    @include flex-centred(flex-start, stretch, row);
    overflow: hidden;
  }
}
