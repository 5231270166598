.app-header {
  position: relative;
  .logo {
    font-size: 1.3em;
    font-weight: 600;
    text-decoration: none !important;
    color: unset !important;
    &:hover {
      color: $blue3 !important;
      @include on-dark-theme {
        color: $blue5 !important;
      }
    }
  }
}
