.project_dashboard {
  @include content-container(3, 3);

  &__loader {
    grid-area: 2 / 2;
  }

  &__description {
    font-size: 16px;
  }

  &__progressbar {
    background: #c4c4c4;
    width: 100%;
    height: 10px;
    display: flex;

    &__tooltip {
      width: 100%;
      span {
        display: flex;
        height: 100%;
      }
    }

    & > div {
      display: flex;
      border: 0 solid #0000007a;

      @include on-dark-theme() {
        border-color: #ffffff7a;
      }

      &:hover {
        border-width: 2px;
      }
    }

    &-UNPROCESSED {
      background-color: #000000a9;
    }

    &-IN_PROGRESS {
      background-color: #94db8991;
    }

    &-FAILED {
      background-color: #821d1d;
    }

    &-OK {
      background-color: #378c29;
    }
  }

  &__tags {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;

    & > .tag {
      @include use-background-color($light-gray2, $dark-gray5);
      padding: 8px 10px 0 10px;

      display: flex;
      flex-direction: column;

      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
      border-radius: 5px;

      & > .value {
        font-size: 20px;
      }
    }
  }
}
